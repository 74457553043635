*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e9e9e9;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1440px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.left-1\/2 {
  left: 50%;
}

.left-0 {
  left: 0;
}

.z-30 {
  z-index: 30;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.flex {
  display: flex;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-full {
  height: 100%;
}

.h-36 {
  height: 9rem;
}

.h-px {
  height: 1px;
}

.h-100 {
  height: 25rem;
}

.w-full {
  width: 100%;
}

.w-36 {
  width: 9rem;
}

.w-1 {
  width: .25rem;
}

.w-100 {
  width: 25rem;
}

.w-14 {
  width: 3.5rem;
}

.flex-initial {
  flex: 0 auto;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.375rem * var(--tw-space-x-reverse));
  margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.border-y-23 {
  border-top-width: 23px;
  border-bottom-width: 23px;
}

.border-b-3 {
  border-bottom-width: 3px;
}

.border-l-40 {
  border-left-width: 40px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-b {
  border-bottom-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-blue-200\/20 {
  border-color: #26385d33;
}

.border-l-blue-100 {
  --tw-border-opacity: 1;
  border-left-color: rgb(35 94 215 / var(--tw-border-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(233 233 233 / var(--tw-bg-opacity));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(38 56 93 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(242 242 242 / var(--tw-bg-opacity));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(35 94 215 / var(--tw-bg-opacity));
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.object-cover {
  object-fit: cover;
}

.p-4 {
  padding: 1rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pr-0 {
  padding-right: 0;
}

.text-center {
  text-align: center;
}

.text-sm {
  font-size: 14px;
  line-height: 18px;
}

.text-2lg {
  font-size: 32px;
  line-height: 42px;
}

.text-base {
  font-size: 16px;
  line-height: 24px;
}

.text-1\.5base {
  font-size: 18px;
  line-height: 28px;
}

.text-2base {
  font-size: 20px;
  line-height: 30px;
}

.text-lg {
  font-size: 24px;
  line-height: 36px;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.text-blue-200 {
  --tw-text-opacity: 1;
  color: rgb(38 56 93 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(233 233 233 / var(--tw-text-opacity));
}

.text-blue-100 {
  --tw-text-opacity: 1;
  color: rgb(35 94 215 / var(--tw-text-opacity));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(242 242 242 / var(--tw-text-opacity));
}

.transition {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@keyframes splide-loading {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  opacity: 0;
  z-index: 0;
  margin: 0 !important;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  pointer-events: none;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.splide__pagination li {
  pointer-events: auto;
  margin: 0;
  line-height: 1;
  list-style-type: none;
  display: inline-block;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  visibility: hidden;
  position: relative;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  margin: 0;
  position: relative;
  list-style-type: none !important;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  contain: strict;
  height: 20px;
  width: 20px;
  border: 2px solid #999;
  border-left-color: #0000;
  border-radius: 50%;
  margin: auto;
  animation: 1s linear infinite splide-loading;
  display: inline-block;
  position: absolute;
  inset: 0;
}

.splide__sr {
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.splide__toggle__pause, .splide__toggle.is-active .splide__toggle__play {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  z-index: 0;
  position: relative;
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

#services li {
  padding-left: 1.25rem;
  position: relative;
}

#services li:before {
  content: "";
  height: .25rem;
  width: .75rem;
  --tw-bg-opacity: 1;
  background-color: rgb(35 94 215 / var(--tw-bg-opacity));
  border-radius: .75rem;
  display: flex;
  position: absolute;
  top: .625rem;
  left: 0;
}

.splide__list {
  align-items: flex-start;
}

.splide__pagination {
  z-index: 10;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.splide__pagination > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

@media (min-width: 960px) {
  .splide__pagination {
    margin-left: -12rem;
    left: 50%;
  }

  .splide__pagination > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }
}

.splide__pagination button {
  height: 1.25rem;
  width: 1.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(233 233 233 / var(--tw-bg-opacity));
  border-radius: 9999px;
  display: flex;
  position: relative;
}

.splide__pagination button:after {
  content: "";
  height: .75rem;
  width: .75rem;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(38 56 93 / var(--tw-bg-opacity));
  border-radius: 9999px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.splide__pagination button.is-active {
  height: 1.25rem;
  width: 1.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(35 94 215 / var(--tw-bg-opacity));
}

.splide__pagination button.is-active:after {
  height: .375rem;
  width: .375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(233 233 233 / var(--tw-bg-opacity));
}

.splide__arrows {
  z-index: 10;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

.splide__arrows > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.splide__arrows button:disabled {
  opacity: .2;
}

.hover\:bg-opacity-60:hover {
  --tw-bg-opacity: .6;
}

@media (min-width: 960px) {
  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:grid {
    display: grid;
  }

  .md\:h-150 {
    height: 37.5rem;
  }

  .md\:h-36 {
    height: 9rem;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:pt-10 {
    padding-top: 2.5rem;
  }

  .md\:pb-8 {
    padding-bottom: 2rem;
  }

  .md\:pb-20 {
    padding-bottom: 5rem;
  }

  .md\:pl-18 {
    padding-left: 4.5rem;
  }

  .md\:pr-36 {
    padding-right: 9rem;
  }

  .md\:pr-28 {
    padding-right: 7rem;
  }

  .md\:pt-8 {
    padding-top: 2rem;
  }

  .md\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .md\:text-3xl {
    font-size: 54px;
    line-height: 70px;
  }

  .md\:text-xl {
    font-size: 42px;
    line-height: 54px;
  }

  .md\:text-2xl {
    font-size: 50px;
    line-height: 65px;
  }

  .md\:text-lg {
    font-size: 24px;
    line-height: 36px;
  }

  .md\:text-2lg {
    font-size: 32px;
    line-height: 42px;
  }
}

@media (min-width: 1440px) {
  .lg\:gap-16 {
    gap: 4rem;
  }
}

.\[\&\.active\]\:border-blue-100.active {
  --tw-border-opacity: 1;
  border-color: rgb(35 94 215 / var(--tw-border-opacity));
}

.\[\&\.active\]\:font-semibold.active {
  font-weight: 600;
}

.\[\&\.active\]\:text-blue-100.active {
  --tw-text-opacity: 1;
  color: rgb(35 94 215 / var(--tw-text-opacity));
}

/*# sourceMappingURL=index.$b21b828b.css.map */
