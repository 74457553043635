@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@splidejs/splide/src/css/core/index";

html {
  scroll-behavior: smooth;
}

#services {

  li {
    @apply relative pl-5;

    &:before {
      content: '';

      @apply absolute left-0 top-2.5 flex w-3 h-1 bg-blue-100 rounded-xl;
    }
  }
}

.splide__list {
  @apply items-start;
}

.splide__pagination {
  @apply flex space-x-4 md:space-x-8 absolute bottom-0 left-0 md:left-1/2 md:-ml-48 z-10;

  button {
    @apply flex w-5 h-5 bg-gray-200 rounded-full relative;

    &:after {
      content: '';
      @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3 h-3 rounded-full bg-blue-200;
    }

    &.is-active {
      @apply w-5 h-5 bg-blue-100;

      &:after {
        @apply bg-gray-200 w-1.5 h-1.5;
      }
    }
  }
}

.splide__arrows {
  @apply flex space-x-3 absolute bottom-0 right-0 z-10;

  button {

    &:disabled {
      @apply opacity-20;
    }
  }
}
