.splide {
  $root: &;

  &__list {
    backface-visibility: hidden;
    display: flex;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }

  &.is-initialized:not( .is-active ) {
    #{ $root }__list {
      display: block;
    }
  }
}
